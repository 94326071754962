<template>
  <div v-if="loading" class="wrapper-lg">
    <sl-loading content="" />
  </div>
  <div class="cliente-fatura pagar" v-else-if="payResponse && ['cartao'].includes(payResponse.method) && payResponse.externalStatus && payResponse.internalStatus === 0">
    <div class="f-left">
      <div style="font-size: 150px" class="text-grey m-b"><i class="fa fa-hourglass" /></div>
      <div class="font-16">Estamos processando seu pagamento, volte novamente mais tarde ou aguarde um retorno por e-mail.</div>
      <div class="m-t"><a @click="$router.push({name: 'suporte.cliente.faturas'})">Voltar para minhas faturas</a></div>
    </div>
  </div>
  <div class="cliente-fatura pagar" v-else>
    <div class="f-left">
      <div class="met-title">Seu método de pagamento</div>
      <div class="metodos">
        <div v-if="hasMethod('cartao')" class="method cc" @click="selecionaTipoMetodo('cartao')" :class="{active: tipoMetodo === 'cartao'}">
          <div class="m-btn">
            <div class="ico">
              <i class="fa-duotone fa-credit-card" />
            </div>
            <div class="label">
              Cartão de Crédito
              <div class="label-desc">Baixa imediata</div></div>
            <i class="fa fa-chevron-down" />
          </div>
          <div class="tipo-pagamento-escolha" v-if="tipoMetodo === 'cartao'">
            <div v-if="!savedMethods || !savedMethods.length">Você não possui nenhum cartão salvo.</div>
            <div v-else>
              <div v-for="m in savedMethods.filter(m => m.tipo === 'cartao')" :key="m.id" class="metodo-seletor">
                <label class="flex items-center">
                  <u-radio v-model="metodoSelecionado" :val="m.id" />
                    <span class="m-l-sm">{{m.nome}}</span>
                    <small class="font-11 text-grey m-l">{{m.descricao}}</small>
                </label>
                <div v-if="edit.editarCartoes"><a @click="excluirMetodoPagamento(m.id, m.nome)" class="remove">Excluir cartão</a></div>
              </div>
            </div>
            <novo-cartao-credito v-if="edit.adicionarCartao" :pessoa="fatura.pessoa" @cartaoSalvo="cartaoSalvo" />
            <div class="tipo-opts">
              <a @click="adicionarCartao" v-if="edit.adicionarCartao">Cancelar cadastro de novo cartão</a><a @click="adicionarCartao" v-else>Adicionar novo cartão</a> &nbsp; | &nbsp;
              <a v-if="edit.editarCartoes" @click="edit.editarCartoes = !edit.editarCartoes">Cancelar edição</a>
              <a v-else @click="edit.editarCartoes = !edit.editarCartoes">Editar</a>

              <div class="opts">
                <u-btn @click="pagarCartao" :loading="payLoading" label="Pagar" no-caps color="green" class="sl-btn" :disable="!metodoSelecionado" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="hasMethod('pix')" class="method cc" @click="selecionaTipoMetodo('pix')" :class="{active: tipoMetodo === 'pix'}">
          <div class="m-btn">
            <div class="ico">
              <img src="@/assets/images/pix2.png" />
            </div>
            <div class="label">
              <div class="label-primary">Pix</div>
              <div class="label-desc">Baixa imediata</div>
            </div>
            <i class="fa fa-chevron-down" />
          </div>
          <div class="tipo-pagamento-escolha" v-if="tipoMetodo === 'pix'">
            <div v-if="!payResponse" class="flex items-center">
              <sl-loading class="size-18" content="" />
              <div class="m-l-sm">Aguarde, estamos gerando os dados para pagamento...</div>
            </div>
            <invoice-generator class="i-generator" :integrador="payResponse" v-else />
          </div>
        </div>
        <div v-if="hasMethod('boleto')" class="method cc" @click="selecionaTipoMetodo('boleto')" :class="{active: tipoMetodo === 'boleto'}">
          <div class="m-btn">
            <div class="ico">
              <img src="@/assets/images/logo-boleto.svg" />
            </div>
            <div class="label">
              Boleto Bancário
              <div class="label-desc">Até 3 dias úteis</div>
            </div>
            <i class="fa fa-chevron-down" />
          </div>
          <div class="tipo-pagamento-escolha" v-if="tipoMetodo === 'boleto'">
            <div v-if="!payResponse" class="flex items-center">
              <sl-loading class="size-18" content="" />
              <div class="m-l-sm">Aguarde, estamos gerando e registrando seu boleto...</div>
            </div>
            <invoice-generator class="i-generator" :integrador="payResponse" v-else />
          </div>
        </div>
<!--        <div class="methods">
          <a class="ipix" v-if="hasMethod('pix')">
            <div class="iico"><img src="@/assets/images/logo-pix.png" /></div>
            <div class="ilegend">Pagar com PIX</div>
          </a>
          <a class="iboleto" v-if="hasMethod('boleto')">
            <div class="iico"><img src="@/assets/images/logo-boleto.svg" /></div>
            <div class="ilegend">Pagar com Boleto</div></a>
        </div>-->
      </div>
    </div>
    <fatura-details :fatura="fatura" is-compra />
  </div>
</template>

<script>

import {checkStatusInvoice, excluirMetodoPagamento, invoice, payInvoice} from "@/domain/business/services"
import SlLoading from "components/layout/components/Loading.vue"
import {UTooltip, URadio} from "uloc-vue"
import {differenceInHours, parseISO} from "date-fns"
import NovoCartaoCredito from "components/suporte/cliente/components/include/NovoCartaoCredito.vue"
import InvoiceGenerator from "components/dashboard/components/Business/window/InvoiceGenerator.vue"
import FaturaDetails from "components/suporte/cliente/components/include/FaturaDetails.vue"

export default {
  name: "ClienteFatura",
  data () {
    return {
      loading: true,
      payLoading: false,
      fatura: null,
      methods: null,
      savedMethods: null,
      method: 'pix',
      defaultMethods: [
        {
          label: 'Cartão de Crédito',
          code: 'cartao',
          disable: true
        },
        {
          label: 'Pix',
          code: 'pix'
        },
        {
          label: 'Boleto Bancário',
          code: 'boleto'
        }
      ],
      tipoMetodo: null,
      metodoSelecionado: null,
      payResponse: null,
      edit: {
        adicionarCartao: false,
        editarCartoes: false
      }
    }
  },
  computed: {
  },
  components: {
    FaturaDetails,
    InvoiceGenerator,
    NovoCartaoCredito,
    SlLoading,
    // UTooltip,
    URadio
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  methods: {
    load (id) {
      this.loading = true
      invoice(id)
          .then(response => {
            if (response.data.invoice.pago) {
              this.$router.push({
                name: 'suporte.cliente.faturas.fatura',
                params: {
                  id: response.data.invoice.id
                }
              })
            }
            this.fatura = response.data.invoice
            this.methods = response.data.methods
            this.savedMethods = response.data.savedMethods
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            // this.loading = false
          })
    },
    hasMethod (m) {
      if (!this.methods || !this.methods.default) {
        return false
      }
      return this.methods.default.methods.includes(m)
    },
    atraso (d) {
      return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    },
    selecionaTipoMetodo (t) {
      if (this.tipoMetodo === t) return
      if (t === 'pix') {
        this.$uloc.dialog({
          title: 'Gerar Chave Pix',
          message: 'Confirma a geração do QRCode Pix? Você terá 5 minutos para pagamento.',
          ok: 'Sim',
          cancel: 'Não',
          color: 'black'
        }).then(() => {
          this.tipoMetodo = t
          this.pay('pix')
        }).catch(() => {})
      } else if (t === 'boleto') {
        this.$uloc.dialog({
          title: 'Emitir Boleto',
          message: 'Confirma a emissão do boleto bancário?',
          ok: 'Sim',
          cancel: 'Não',
          color: 'black'
        }).then(() => {
          this.tipoMetodo = t
          this.pay('boleto')
        }).catch(() => {})
      } else {
        this.tipoMetodo = t
      }
    },
    adicionarCartao () {
      this.edit.adicionarCartao = !this.edit.adicionarCartao
    },
    cartaoSalvo (cartao) {
      this.savedMethods.push(cartao)
      this.metodoSelecionado = cartao.id
      this.edit.adicionarCartao = false
      this.edit.editarCartoes = false
    },
    excluirMetodoPagamento (id, nome) {
      this.$uloc.dialog({
        title: 'Excluir método de pagamento',
        message: `Você tem certeza que deseja excluir ${nome}?`,
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Excluindo método de pagamento...'})
        excluirMetodoPagamento(id)
            .then(response => {
              this.$uloc.loading.hide()
              const index = this.savedMethods.findIndex(cartao => cartao.id === id)
              if (index !== -1) {
                if (this.metodoSelecionado === id) {
                  this.metodoSelecionado = null
                }
                this.savedMethods.splice(index, 1)
              }
            }).catch(error => {
              this.alertApiError(error)
          this.$uloc.loading.hide()
        })
      }).catch(() => {})
    },
    pagarCartao () {
      const extra = {
        dadosCartao: null,
        metodoSalvo: this.metodoSelecionado
      }
      this.pay('cartao', extra)
    },
    pay (method, extra = null) {
      this.payLoading = true
      this.payResponse = null
      payInvoice(this.fatura.id, this.methods.default.name, method, extra)
          .then(response => {
            this.payLoading = false
            this.payResponse = response.data
            if (response.data.internalStatus === 100) {
              this.redirecionaSucesso()
            } else {}
            if (['pix'].includes(method)) {
              try {
                this.invoiceCheckInterval = setInterval(() => {
                  this.checkStatusInvoice()
                }, 10000)
              } catch (e) {
                console.error(e)
                clearInterval(this.invoiceCheckInterval)
              }
            }
            console.log(response)
          })
          .catch(error => {
            this.payLoading = false
            this.alertApiError(error)
          })
    },
    async checkStatusInvoice () {
      const response = await checkStatusInvoice(this.fatura.id)
      if (response.data && response.data.invoice && response.data.invoice.pago) {
        this.invoiceCheckInterval && clearInterval(this.invoiceCheckInterval)
        this.fatura.pago = true
        this.redirecionaSucesso()
      }
    },
    redirecionaSucesso () {
      this.$router.push({
        name: 'suporte.cliente.faturas.fatura',
        params: {
          id: this.fatura.id
        },
        query: {
          sucessoPagamento: 1
        }
      })
    }
  }
}
</script>
